import Form, { formCreate } from '@/found/components/form';
import request from '@/found/utils/request';
import _ from 'xe-utils';
import BudgetList from './budget-list.vue';
import CompanyList from './company_list.vue';

formCreate.component('BudgetList', BudgetList);
formCreate.component('CompanyList', CompanyList);
export default {
  extends: Form,
  components: {
    BudgetList,
    CompanyList,
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      rule: [],
      formFunctionCode: 'quota_control_form',
    };
  },

  methods: {

    // 设置规则
    setRule(item) {
      const v = item;
      if (v.field === 'fiscalYear') { // 财年
        v.restful = '/tpm/tpmFiscalYearSettingController/list';
        v.restfulParams = { pageSize: -1, enableStatus: '009' };
        v.props = {
          ...v.props,
          remoteParams: 'yearName',
          remote: true,
          filterable: true,
        };
        v.optionsKey = {
          label: 'yearName',
          value: 'year',
        };
      }
      if (v.field === 'budgetSubjectsName') { // 选择产品
        v.props = {
          ...v.props,
          params: {
            functionCode: 'budget_list',
          },
          getValue: (field) => this.getFieldValue(field),
          title: v.title,
        };
      }
      if (v.field === 'companyName') { // 公司主体
        v.props = {
          ...v.props,
          params: {
            functionCode: 'company_list',
          },
          getValue: (field) => this.getFieldValue(field),
          title: v.title,
        };
      }
      if (v.field === 'changeAmount') {
        if (this.formConfig.type === 'addition') {
          v.title = '追加金额';
        } else if (this.formConfig.type === 'reduce') {
          v.title = '削减金额';
        }
        v.on = {
          input: (e) => {
            const canUseAmount = this.getRule('canUseAmount');
            const current = e || 0;
            let result = 0;
            if (this.formConfig.type === 'addition') {
              result = _.add(canUseAmount.value, Number(current));
            } else if (this.formConfig.type === 'reduce') {
              result = _.subtract(canUseAmount.value, Number(current));
            }
            this.setValue({
              afterAmount: result,
            });
          },
        };
      }
      if (v.field === 'afterAmount') {
        if (this.formConfig.type === 'addition') {
          v.title = '追加后余额';
        } else if (this.formConfig.type === 'reduce') {
          v.title = '削减后余额';
        }
        v.props = {
          ...v.props,
          disabled: true,
        };
      }
      return v;
    },

    // 完成渲染
    formComplete() {
      const { type } = this.formConfig;
      this.disabled(true, 'budgetSubjectsName');
      const feeBudgetType = this.getRule('feeBudgetType');
      feeBudgetType.on.change = (e) => {
        if (e) {
          this.disabled(false, 'budgetSubjectsName');
          const budgetSubjectsName = this.getRule('budgetSubjectsName');
          budgetSubjectsName.props.params = {
            ...budgetSubjectsName.props.params,
            paramData: {
              subjectsTypeCode: e,
            },
          };
        } else {
          this.disabled(true, 'budgetSubjectsName');
        }
        this.setValue({
          budgetSubjectsName: null,
          budgetSubjectsCode: null,
        });
      };

      const companyName = this.getRule('companyName');
      companyName.on.change = (e) => {
        if (e && e.length > 0) {
          this.setValue({
            companyName: e[0].companyName,
            companyCode: e[0].companyCode,
          });
        } else {
          this.setValue({
            companyName: null,
            companyCode: null,
          });
        }
      };

      if (this.formConfig.type === 'addition' || this.formConfig.type === 'reduce') {
        this.hiddenFields(false, ['canUseAmount', 'changeAmount', 'afterAmount']);
        this.hiddenFields(true, ['totalAmount']);
        this.disabled(true, ['fiscalYear', 'feeBudgetType', 'budgetSubjectsName', 'totalAmount']);
      } else {
        this.hiddenFields(true, ['canUseAmount', 'changeAmount', 'afterAmount']);
        this.hiddenFields(false, ['totalAmount']);
      }
      this.disabled(true, 'canUseAmount');
      const budgetSubjectsName = this.getRule('budgetSubjectsName');
      budgetSubjectsName.on.change = (e) => {
        if (e && e.length > 0) {
          this.setValue({
            budgetSubjectsCode: e[0].budgetSubjectsCode,
            budgetSubjectsName: e[0].budgetSubjectsName,
          });
        } else {
          this.setValue({
            budgetSubjectsCode: null,
            budgetSubjectsName: null,
          });
        }
      };

      if (type !== 'add') {
        request.get('/tpm/tpmFeeLimit/query', { id: this.formConfig.row.id }).then((res) => {
          if (res.success) {
            this.setValue(res.result);
          }
        });
      }
    },

    // 提交
    submit() {
      let url = '/tpm/tpmFeeLimit/save';
      const formData = this.getFormData(); // 获取表单的值
      if (formData) {
        // 有id  就是编辑
        if (this.formConfig.row && this.formConfig.row.id) {
          formData.id = this.formConfig.row.id;
        }
        if (this.formConfig.type === 'addition' || this.formConfig.type === 'reduce') {
          url = '/tpm/tpmFeeLimit/change';
          if (this.formConfig.type === 'addition') {
            formData.operateType = 'additional';
          } else if (this.formConfig.type === 'reduce') {
            formData.operateType = 'cut_out';
          }
          delete formData.id;
          delete formData.afterAmount;
          delete formData.canUseAmount;
        }

        request.post(url, formData).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '操作成功',
            });
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      }
    },
  },
};
