import TablePage from '@/found/components/table_page';

export default {
  name: 'detail_list',
  extends: TablePage,
  props: {
    propsObj: Object,
  },
  data() {
    return {
      params: {
        limitCode: this.propsObj.limitCode,
      },
    };
  },

  created() {
    this.getConfigList('detail_list');
  },
};
