import TablePage from '@/found/components/table_page';
import Modal from '@/found/components/modal';
import Form from './form/index';
import DetailForm from './form/detail';

export default {
  name: 'fee_rule_list',
  extends: TablePage,
  data() {
    return {
      formConfig: {},
      modalConfig: {
        title: '新增',
        visible: false,
        type: '',
      },
    };
  },
  components: {
    Modal,
    Form,
    DetailForm,
  },
  methods: {
    // 按钮点击事件
    modalClick({ val, row }) {
      this.formConfig = {};
      this.formName = 'Form';
      if (val.code === 'add') {
        this.modalConfig.title = '新增';
        this.formConfig = {
          type: val.code,
        };
        this.openModal();
      } else if (val.code === 'edit') {
        this.modalConfig.title = '编辑';
        this.formConfig = {
          type: val.code,
          row,
        };
        this.openModal();
      } else if (val.code === 'view') {
        this.modalConfig.title = '查看';
        this.formConfig = {
          row,
          type: val.code,
        };
        this.openModal();
      } else if (val.code === 'addition') {
        this.modalConfig.title = '追加';
        this.formConfig = {
          row,
          type: val.code,
        };
        this.openModal();
      } else if (val.code === 'reduce') {
        this.modalConfig.title = '削减';
        this.formConfig = {
          row,
          type: val.code,
        };
        this.openModal();
      } else if (val.code === 'check_detail') {
        this.formName = 'DetailForm';
        this.modalConfig.title = '查看明细';
        this.propsObjInData.limitCode = row.limitCode;
        this.openModal();
      }
    },
  },
  created() {
    this.getConfigList('quota_control_list');
  },
};
